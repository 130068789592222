import React from 'react';
import '../../scss/style.scss';
import es6Shim from 'es6-shim';
import ReactTooltip from 'react-tooltip';
export default function GlobalStyle(props) {
  return null
};
    import { render } from 'react-dom'                                    
    if (window.reactComponents === undefined) {                           
      window.reactComponents = {}                                         
    }                                                                     
    window.reactComponents.GlobalStyle = (function() {                   
    let _args = {}                                                        
                                                                          
    return {                                                              
      init: function (Args) {                                             
        _args = Args                                                      
      },                                                                  
      render: function () {                                               
        const  { id, ...props } = JSON.parse(_args)                       
        render(<GlobalStyle {...props}/>, document.getElementById(id))   
      }                                                                   
    }                                                                     
  }())